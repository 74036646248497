import { combineEpics } from 'redux-observable';

import notificationsEpic from './NotificationGenerator/epic';
import signInEpic from './SignIn/epic';
import rootSignInEpic from './RootSignIn/epic';
import oAuthSignInEpic from './OAuthSignIn/epic';
import forgotPasEpic from './ForgotPass/epic';
import modalWindowEpic from './ModalWindow/epic';
import signUpEpic from './SignUp/epic';
import signUp2Epic from './SignUp2/epic';
import settingProfileEpic from './SettingProfile/epic';
import pvInstallersEpic from './PvInstallers/epic';
import pvInstallersDetailsEpic from './PVInstallersDetails/epic';
import viewerDetailsEpic from './ViewersDetails/epic';
import getOEMsEpic from './Users/epic';
import monitoringEpic from './Monitoring/epic';
import resetPasswordEpic from './ResetPass/epic';
import dataListEpic from './DataList/epic';
import userEpic from './UserDetails/epic';
import installedSMEpic from './InstalledSM/epic';
import verifyChangeEmailEpic from './VerifyChangeEmail/epic';
import sendNotificationEpic from './MonitoringNotification/epic';
import devicesTableEpic from './DevicesTable/epic';
import basicAuthSignInEpic from './BasicAuthSignIn/epic';
import paymentsEpic from './Payments/epic';
import sensorsOauth2Epic from './SensorsOauth2/epic';
import loadManagementDashboardEpic from './LoadManagementDashboard/epic';
import IPFieldEpic from '../components/ReduxFormFields/IPField/epic';
import overviewEpic from './Overview/epic';
import cloudStatusEpic from './CloudStatusWindow/epic';
import documentationEpic from './Documentation/store/epic';

export default combineEpics(
  signInEpic,
  oAuthSignInEpic,
  rootSignInEpic,
  notificationsEpic,
  forgotPasEpic,
  modalWindowEpic,
  signUpEpic,
  IPFieldEpic,
  signUp2Epic,
  settingProfileEpic,
  sendNotificationEpic,
  getOEMsEpic,
  pvInstallersEpic,
  pvInstallersDetailsEpic,
  viewerDetailsEpic,
  monitoringEpic,
  resetPasswordEpic,
  dataListEpic,
  userEpic,
  installedSMEpic,
  verifyChangeEmailEpic,
  devicesTableEpic,
  basicAuthSignInEpic,
  paymentsEpic,
  sensorsOauth2Epic,
  loadManagementDashboardEpic,
  overviewEpic,
  cloudStatusEpic,
  documentationEpic
);
