import React from 'react';
import { MenuItem, ListItemText, FormControl, Select, Checkbox } from '@mui/material';
import PropTypes from 'prop-types';
import i18n from '../../../../../i18n';
import { SELECT_ALL } from '../../constants';
import { arrOfNamesDays, getActiveDaysOptions } from '../../helper';
import ShowErrorValidation from '../ShowErrorValidation';

import './index.scss';

const renderContentUsingChangedValue = (checked) => {
  if (checked.every((value) => Boolean(value))) {
    return <div className="days-week">{i18n.t(SELECT_ALL)}</div>;
  }

  if (checked.every((value) => value === 0)) {
    return <div className="select-days">{i18n.t('selectDays')}</div>;
  }
  if (checked.every(({ value }) => value === true)) {
    return <div className="days-week">{SELECT_ALL}</div>;
  }

  return (
    <div className="days-week">
      {checked.reduce((acm, value, index) => (value ? (`${acm} ${i18n.t(arrOfNamesDays[index]).slice(0, 3)}`) : acm), '')}
    </div>
  );
};
const options = getActiveDaysOptions();

const SelectDays = (props) => {
  const { input, meta } = props;
  const { touched, error, forceError, languageToSync } = meta;

  const toggleSelectAll = () => {
    const newInputValue = [...input.value];
    const isAllSelected = newInputValue.every((value) => value === 1);
    input.onChange(newInputValue.map(() => Number(!isAllSelected)));
  };

  const handleOnChange = (index) => {
    const newInputValue = [...input.value];
    newInputValue[index] = Number(!newInputValue[index]);
    input.onChange(newInputValue);
  };

  return (
    <FormControl className="days-form-control">
      <Select
        {...input}
        name="activeDays"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 138
            }
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center'
          },
          classes: {
            paper: 'days-select-menu-paper',
            root: 'days-select-menu-root'
          }
        }}
        SelectDisplayProps={{
          style: {
            borderBottom: '1px solid #999',
            borderRadius: 0,
            paddingTop: 8,
            paddingBottom: 8,
            paddingLeft: 0
          }
        }}
        value={input.value}
        multiple
        renderValue={renderContentUsingChangedValue}
        onChange={() => null}
      >
        <MenuItem
          classes={{ root: 'days-menu-item' }}
          disableTouchRipple
          onClick={toggleSelectAll}
          key={SELECT_ALL}
          value={input.value.every((value) => value === 1)}
        >
          <Checkbox
            type="checkbox"
            name="selectAll"
            checked={input.value.every((value) => value === 1)}
          />
          <ListItemText className="days-list-item" primary={i18n.t(SELECT_ALL)} />
        </MenuItem>
        <hr className="days-menu-item__HR" />
        {options.map(({ key, label }, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={key}>
            <MenuItem
              key={key}
              classes={{ root: 'days-menu-item' }}
              disableTouchRipple
              value={input.value[index]}
              onClick={() => handleOnChange(index)}
            >
              <Checkbox name={label} checked={Boolean(input.value[index])} />
              <ListItemText className="days-list-item" primary={i18n.t(arrOfNamesDays[index])} />
            </MenuItem>
            {options.length !== (index - 1) && <hr className="days-menu-item__HR" />}
          </div>
        ))}
      </Select>
      <ShowErrorValidation
        textOfError={i18n.t('selectDaysError')}
        validations={touched && (forceError || error || languageToSync)}
      />
    </FormControl>
  );
};

SelectDays.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  meta: PropTypes.instanceOf(Object).isRequired

};

export default SelectDays;
