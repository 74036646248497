import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getJwtFromCookies } from '../../api';

import { Preloader } from '../../components';

import './index.scss';

const Payments = () => {
  const paymentUrl = useSelector((state) => state?.payments?.url || '');

  useEffect(() => {
    const jwt = getJwtFromCookies();

    if (jwt) {
      window.location.href = `${paymentUrl}/${jwt}`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Preloader />;
};

export default Payments;
