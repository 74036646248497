import view from './view.json';

const replace = {
  // C
  changeSMID: view.changeHelionID,

  // R
  reqSMID: view.reqHelionID,

  // S
  SMID: view.HelionID,
  SMIDTolltip: view.HelionIDTolltip
};

export default replace;
