import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import shortid from 'shortid';

import { getSignInUserSelector } from '../../../redux-store/selectors/signIn';

import i18n from '../../../i18n';

/**
 * Array of semi tabs and role types in monitoring
 * @constant
 * @memberof module:Monitoring
 */
const tabsByRoles = [
  {
    label: 'monitoring',
    link: '/monitoring',
    allowedRoles: ['root', 'solar_admin', 'property_management', 'pv_installer', 'pv_installer_employee', 'pv_installer_employee_read_only', 'pv_installer_employee_read_install', 'oem', 'oem_employee', 'oem_employee_read_only']
  },
  {
    label: 'notification',
    link: '/monitoring/notification',
    allowedRoles: ['pv_installer', 'pv_installer_employee', 'pv_installer_employee_read_only', 'pv_installer_employee_read_install', 'oem', 'oem_employee', 'oem_employee_read_only']
  }
];

/**
 * Renders tabs (relative to user role who is viewing) for Monitoring.
 * @memberof module:Monitoring
 * @param {Object} param Must contain next Object: { user: { role: { type: 'string' } }, ... }
 */
const SemiTabs = () => {
  const user = useSelector(getSignInUserSelector);

  if (!user?.role) return null;

  return (
    <ul className="nav nav-tabs m-tabs-line m-tabs-line--danger m-tabs-line--2x">
      {tabsByRoles?.map(
        (tab) => (
          tab.allowedRoles.includes(user.role.type)
            ? (
              <li
                key={shortid.generate()}
                className="nav-item m-tabs__item"
              >
                <NavLink
                  className="nav-link m-tabs__link"
                  activeClassName="active show"
                  exact
                  to={tab.link}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...tab.componentProps}
                >
                  {i18n.t(tab.label)}
                </NavLink>
              </li>
            )
            : null
        )
      )}
    </ul>
  );
};

export default SemiTabs;
