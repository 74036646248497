export const multiselectConfig = {
  multiselectContainer: {
    display: 'flex',
    alignItems: 'end',
    width: 'min-content',
    minWidth: '200px',
    maxWidth: '250px',
    minHeight: '38px',
    color: '#161B2BCC'
  },
  searchBox: {
    width: '100%',
    border: '1px solid #00000019',
    backgroundColor: '#F8F8F8',
    color: '#161B2BCC'
  },
  chips: {
    backgroundColor: '#888888',
    whiteSpace: 'normal'
  },
  optionContainer: {
    position: 'absolute',
    width: '100%',
    border: 'none',
    backgroundColor: '#f8f8f8',
    top: '4px',
    boxShadow: '0px 3px 6px #00000029'
  },
  inputField: {
    maxWidth: '240px'
  }
};

export const READ_ONLY_ROLES = ['pv_installer_employee_read_only', 'oem_employee_read_only'];
