import React from 'react';
import { Link } from 'react-router-dom';

import './index.scss';
import oemLogo from './logos/logo.png';
import oemLogo2 from './helion-main.png';

const isNotLoggedIn = () => window.location.href.includes('login')
  || window.location.href.includes('signup')
  || window.location.href.includes('forgot-password')
  || window.location.href.includes('verify');

/**
 * Renders Solar Manager Logo for all pages
 */
const Logo = () => (
  <div className="m-login__log">
    <Link to="/">
      <img src={isNotLoggedIn() ? oemLogo : oemLogo2} alt="logo_solar_manager" className="topBarLogo" />
    </Link>
  </div>
);

export default Logo;
